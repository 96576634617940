<template>
  <div class="popup">
    <div class="popup-header">
      <span class="close" @click="closeModal">Venster sluiten X</span>
    </div>
    <div class="popup-body">
      <h1>{{ title }}</h1>
      <p>
        {{ description }}
      </p>

      <div class="popup-numbers" v-if="showNumbers">
        <p>
          <span>Jaarlijkse besparing: </span>
          <span>€ {{ getYearlySavings }},-</span>
        </p>
        <p><span>Investering</span> € {{ getInvestment }},-</p>
        <p>
          <span>Subsidie</span> <span>€ {{ getSubsidy }},-</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    showNumbers: Boolean,
    yearlySavings: Number,
    investment: Number,
    subsidy: Number,
  },
  computed: {
    getInvestment() {
      return this.investment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getSubsidy() {
      return this.subsidy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getYearlySavings() {
      return this.yearlySavings
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  methods: {
    closeModal() {
      this.$emit("closePopup");
    },
  },
};
</script>

<style lang="scss">
.popup {
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 50px;
  width: 400px;
  padding: 16px;
  background: rgba(2255, 255, 255, 0.9);
  z-index: 100;
  display: flex;
  flex-direction: column;

  .popup-body {
    flex: 1 1 auto;
    overflow: auto;

    h1 {
      font-size: 2rem;
      margin-bottom: 16px;
    }

    p {
      margin-top: 0;
      font-size: 16px;
      line-height: 1.6;
    }
  }

  .popup-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .close {
      cursor: pointer;
      padding: 4px 8px;
      border: 1px solid transparent;

      &:hover {
        border-color: rgb(22, 22, 22);
      }
    }
  }

  .popup-numbers {
    p {
      font-weight: 400;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;

      &:not(first-child) {
        margin-top: 4px;
      }
    }
  }
}
</style>